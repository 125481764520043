<script lang="ts" setup>
const { t } = useI18n({
  useScope: 'local',
});

const emit = defineEmits(['item-clicked']);
</script>

<template>
  <nav class="nav">
    <ul class="list">
      <TwNavItem to="/suche" @click="emit('item-clicked')">
        <template #iconBefore>
          <Icon name="bi:search" />
        </template>
        {{ t('search') }}
      </TwNavItem>

      <TwNavItem to="/registrieren" @click="emit('item-clicked')">
        <template #iconBefore>
          <Icon name="bi:person-add" />
        </template>
        {{ t('register') }}
      </TwNavItem>

      <TwNavItem to="/login" @click="emit('item-clicked')">
        <template #iconBefore>
          <Icon name="bi:box-arrow-in-right" />
        </template>
        {{ t('login') }}
      </TwNavItem>
    </ul>
  </nav>
</template>

<style lang="scss">
@import '~/assets/styles/tw-variables.scss';
@import '~/assets/styles/tw-mixins.scss';

.list {
  display: flex;
  flex-direction: column;

  @include is-desktop {
    flex-direction: row;
    justify-content: end;
  }
}
</style>

<i18n lang="json">{
  "de": {
    "search": "Suche",
    "register": "Registrieren",
    "login": "Anmelden"
  },
  "en": {
    "search": "Search",
    "register": "Register",
    "login": "Login"
  }
}</i18n>